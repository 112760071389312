import { ATTRIBUTES_TRANSLATED, getAttributesFromUrl } from '@features/plp/algoliaUtils'
import { useProductParams } from '@hooks/useProductParams'
import Log from '@services/Log'
import { SearchClient } from 'algoliasearch'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useReducer, useState } from 'react'
import { useClearRefinements, useCurrentRefinements, useInstantSearch } from 'react-instantsearch-hooks-web'
import {
  ClearAllStyledButtonAsLink,
  SeeResultsStyledButton,
  StyledAccordion,
  StyledAccordionSummary,
  StyledCloseIconButtonContainer,
  StyledFiltersDrawer,
  StyledFiltersDrawerContent,
  StyledFiltersFooter,
  StyledFiltersHeader,
  StyledFiltersHeaderTitle,
} from './PlpFilter.style'
import FilterListAlgolia from './components/FilterListAlgolia'
//TODO RESTORE import { useFrameGenius } from '../FrameGenius/FrameGeniusContext'
import { SuggestedSizeFeature } from '@typesApp/FrameGenius/frameGenius'
import { RangeSlider, useRangeSlider } from './components/RangeSlider'
import { SizeAdvisorSuggestion } from './components/SizeAdvisorSuggestion'

import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { algoliaFilterGenderCategories } from '@constants/algolia'
import { sendSearchFilteringEvent } from '@foundation/analytics/tealium/lib'
import { IAlgoliaHit } from '@typesApp/product'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { usePlpDispatch, usePlpState } from '@utils/Plp/PlpContext'
import { flatRefinementList, getFacets } from '@utils/Plp/Utils'
import plpFilterAlgoliaReducer, { IPlpFilterAlgoliaState } from '@utils/Plp/plpFilterAlgoliaReducer'
import { convertToPascal } from '@utils/common'
import type { SearchResults } from 'algoliasearch-helper'
import { useSearchParams } from 'next/navigation'
import SuggestedFacetFilterWrapper from './components/SuggestedFacetFilterWrapper'

export interface PlpFilterAlgoliaProps {
  searchClient: SearchClient
  handleRoute?: (parameters: string[]) => void
  categoryFilter?: string
}

const FACET_SIZE_NAME = `${ATTRIBUTES_TRANSLATED}FRAME_SIZE_DISPLAY_RANGE`
const HINGE_DISTANCE_RANGE = `${ATTRIBUTES_TRANSLATED}HINGE_DISTANCE_RANGE`

const PlpFilterAlgolia = ({ searchClient, handleRoute, categoryFilter }: PlpFilterAlgoliaProps) => {
  const { results, setIndexUiState } = useInstantSearch()
  const { items: currentRefinements } = useCurrentRefinements()
  const customerSegment = useCustomerSegmentsUtil()[0]
  const { range } = useRangeSlider({ attribute: `sortPrice_${customerSegment}` })

  // @ts-ignore
  const { nbHits, __isArtificial, renderingContent } = results as SearchResults<IAlgoliaHit>

  const getPriceRangeFromQuery = (parameters: ReturnType<typeof generateParameters>): [number, number] => {
    const { priceFrom, priceTo } = parameters
    const internalPriceFrom = priceFrom && isFinite(priceFrom) ? +priceFrom : 0
    const internalPriceTo = priceTo && isFinite(priceTo) ? +priceTo : 0

    return [internalPriceFrom, internalPriceTo]
  }

  const { refine: clearAllFacets } = useClearRefinements()
  const plpState = usePlpState()
  const plpDispatch = usePlpDispatch()
  const searchParams = useSearchParams()
  const { generateParameters } = useProductParams()
  const query = plpState.searchParams?.query ?? searchParams?.get('query')
  const parameters = useMemo(() => {
    return generateParameters(customerSegment)
  }, [customerSegment, generateParameters])

  const plpFilterAlgoliaInitialState: IPlpFilterAlgoliaState = {
    currentPlpFacets: {},
    selectedFacets: {},
    suggestedPlpFacets: [],
    hingesRangeFacet: [],
    priceRange: {
      values: getPriceRangeFromQuery(parameters),
      isOpen: false,
      shouldRender: range?.min !== range.max,
    },
    isSizeAdvisorActive: false, // TODO SizeAdvisorUtil.getSizeAdvisorPlpToggleStatus(),
  }

  const [state, dispatch] = useReducer(plpFilterAlgoliaReducer, plpFilterAlgoliaInitialState)

  useEffect(() => {
    const shouldRender = Boolean(range.min && range.max && range.min < range.max)
    dispatch({
      type: 'SET_FILTER_PRICE_RANGE',
      payload: {
        isOpen: shouldRender ? state.priceRange.isOpen : false,
        shouldRender,
      },
    })
  }, [range.max, range.min, state.priceRange.isOpen])

  useEffect(() => {
    plpDispatch({
      type: 'SET_INTERNAL_APPLIED_FILTERS',
      payload: {
        _internalAppliedFacets: currentRefinements,
        shouldLoadUngrouped: false,
      },
    })
  }, [currentRefinements, plpDispatch])

  useEffect(() => {
    async function getFacetsWrapper() {
      const isAlgoliaGenderPlp = algoliaFilterGenderCategories.some(
        genderPlpPage => `categories:"${genderPlpPage}"` === categoryFilter
      )

      let orderedFacets = renderingContent?.facetOrdering?.facets?.order
      if (isAlgoliaGenderPlp) {
        orderedFacets = orderedFacets?.filter(facet => facet !== `${ATTRIBUTES_TRANSLATED}GENDER`)
      }

      const { facets, suggestedFacets } = await getFacets(
        searchClient,
        plpState.groupedIndexName,
        orderedFacets,
        customerSegment,
        plpState.showOffersFilters,
        plpState.algoliafilterCategory ?? '',
        query as string
      )
      dispatch({
        type: 'SET_CURRENT_PLP_FACETS',
        payload: {
          currentPlpFacets: facets,
          suggestedPlpFacets: suggestedFacets,
        },
      })
    }
    if (plpState.firstLoad) {
      getFacetsWrapper()
    }
  }, [
    categoryFilter,
    customerSegment,
    plpState.algoliafilterCategory,
    plpState.firstLoad,
    plpState.groupedIndexName,
    plpState.showOffersFilters,
    query,
    renderingContent?.facetOrdering?.facets?.order,
    searchClient,
    searchParams,
  ])

  const [selectedFacets, setSelectedFacets] = useState<{
    [key: string]: string[]
  }>(getAttributesFromUrl(parameters, customerSegment))

  const orderedFacetsKeys = renderingContent?.facetOrdering?.facets?.order
  const valuesFacetsKeys = renderingContent?.facetOrdering?.values

  // const [hingesRangeFacet, setHingeRangeFacet] = useState<any>(undefined)

  // const [clearAllClicked, setClearAllClicked] = useState(false)

  const filteredOrderedFacets = useMemo(() => {
    if (!state.currentPlpFacets || !orderedFacetsKeys || !valuesFacetsKeys) return []

    interface ISortingFacetValues {
      count: (value: Record<string, number>) => string[]
      alpha: (value: Record<string, number>) => string[]
      hidden: (value: Record<string, number>, sortingArray: string[] | undefined) => string[]
      default: (value: Record<string, number>) => string[]
    }

    return orderedFacetsKeys.reduce<{ key: string; value: string[] }[]>((acc, key) => {
      if (state.currentPlpFacets[key]) {
        const value = state.currentPlpFacets[key]
        const sortingType = valuesFacetsKeys[key]?.sortRemainingBy
        const sortingArray = valuesFacetsKeys[key]?.order

        const sorting: ISortingFacetValues = {
          count: value => {
            return Object.entries(value)
              .map(([key, hitsCount]) => ({
                key,
                hitsCount,
              }))
              .sort((a, b) => Number(b.hitsCount) - Number(a.hitsCount))
              .map(({ key }) => key)
          },
          alpha: value => {
            return Object.keys(value).sort((a, b) => a.localeCompare(b))
          },
          hidden: (value, sortingArray) => {
            if (!sortingArray) return Object.keys(value)

            const keys = Object.keys(value)
            return sortingArray.reduce<string[]>((acc, key) => {
              if (keys.includes(key.toLowerCase())) {
                acc.push(key.toLowerCase())
              }
              return acc
            }, [])
          },
          default: value => Object.keys(value),
        }

        const newValue = sorting[sortingType || 'default'](value, sortingArray)
        acc.push({ key, value: newValue })
      }
      return acc
    }, [])
  }, [state.currentPlpFacets, orderedFacetsKeys, valuesFacetsKeys])

  const { t } = useTranslation()

  const onFacetChange = (selection: string, label: string, parent: string, setFacet: (string) => void) => {
    Log.info(`facet selected: ${label} parent: ${parent}`)

    setFacet(selection)
    const currentSelectedFacets = { ...selectedFacets }

    if (currentSelectedFacets[parent]) {
      const selectedValues = currentSelectedFacets[parent]
      const index = selectedValues.indexOf(selection)
      currentSelectedFacets[parent] =
        index > -1 ? selectedValues.filter(value => value !== selection) : [...selectedValues, selection]
    } else {
      currentSelectedFacets[parent] = [selection]
    }

    setSelectedFacets({
      ...currentSelectedFacets,
    })
  }

  const onRangeSliderChange = newValues => {
    let internalValues = newValues
    const shouldReset = internalValues.toString() === [range.min, range.max].toString()

    if (shouldReset) {
      internalValues = [0, 0]
    }

    dispatch({
      type: 'SET_FILTER_PRICE_RANGE',
      payload: {
        values: internalValues,
      },
    })

    setIndexUiState(prevState => ({
      ...prevState,
      range: shouldReset ? undefined : { [`sortPrice_${customerSegment}`]: internalValues.join(':') },
    }))
  }

  const onClearAll = () => {
    clearAllFacets()
    // setClearAllClicked(true)
    dispatch({
      type: 'SET_FILTER_PRICE_RANGE',
      payload: {
        values: [0, 0],
      },
    })
    // SizeAdvisorUtil.setSizeAdvisorPlpToggleStatus(false)
    // setSelectedFacets({})
  }

  const getFacetPropertyName = (value: string) => {
    if (!value) return ''
    const facetPropertyFields = value.split('.')
    if (facetPropertyFields.length < 2) return ''
    return convertToPascal(facetPropertyFields[1])
  }

  const onResultPress = () => {
    const flattenRefinements = flatRefinementList(currentRefinements)
    plpDispatch({
      type: 'SET_MULTIPLE',
      payload: {
        openDrawerFilters: false,
        shouldLoadUngrouped: true,
        _internalAppliedFacets: flattenRefinements,
        appliedFacets: flattenRefinements,
      },
    })

    const parameters = flattenRefinements.map(refinement => `${refinement.attribute}=${refinement.value}`)

    handleRoute && handleRoute(parameters)

    const analyticsParameters = flattenRefinements.map(
      refinement => `${getFacetPropertyName(refinement.attribute)}=${refinement.value}`
    )
    // Analytics
    // TODO sendFilterSelectedEvent(selectedFacets, nbHits, frameGeniusData)
    sendSearchFilteringEvent({
      facetValues: analyticsParameters.join('|'),
      qnt: nbHits,
    })
  }

  // TODO RESTORE const frameGeniusData = useFrameGenius()
  const frameGeniusStoreconfs = plpState.storeInfo?.xStoreCfg.frameGenius
  const isSizeAdvisorEnabled = frameGeniusStoreconfs?.isSizeAdvisorEnabled

  const isFrameGeniusToggleEnabled = false // TODO SizeAdvisorUtil.getSizeAdvisorPlpToggleStatus()

  const suggestedSize: SuggestedSizeFeature = {
    label: '',
    optimalHinge: 0,
    maxHinge: 0,
    minHinge: 0,
  } //TODO RESTORE frameGeniusData.analysisResults.size
  const isHingesFilterApplicable = () => {
    const isHingesFilterPresentInFacets = !!(
      selectedFacets &&
      selectedFacets[HINGE_DISTANCE_RANGE] &&
      selectedFacets[HINGE_DISTANCE_RANGE].length
    )

    return (
      isSizeAdvisorEnabled &&
      isFrameGeniusToggleEnabled &&
      !isHingesFilterPresentInFacets &&
      /*!!hingesRangeFacet &&*/
      !!suggestedSize?.minHinge &&
      !!suggestedSize?.maxHinge
    )
  }

  // const buildHingesDistanceFilter = () => {
  //   return `${suggestedSize?.minHinge}:${suggestedSize?.maxHinge}`
  // }

  const buildAndAddHingesDistanceFilter = () => {
    // if (!hingesRangeFacet) return

    const alreadyAppliedSizeFacets = selectedFacets && selectedFacets[FACET_SIZE_NAME]

    if (alreadyAppliedSizeFacets && alreadyAppliedSizeFacets.length) {
      const newFacetsWithoutSizes = { ...selectedFacets }
      delete newFacetsWithoutSizes[FACET_SIZE_NAME]

      setSelectedFacets(newFacetsWithoutSizes)
    } else {
    }
  }

  const toggleHingesFacet = () => {
    const currentStatus = false // TODO SizeAdvisorUtil.getSizeAdvisorPlpToggleStatus()

    buildAndAddHingesDistanceFilter()
    dispatch({
      type: 'SET_SIZE_ADVISOR_STATUS',
      payload: !currentStatus,
    })
    // TODO SizeAdvisorUtil.setSizeAdvisorPlpToggleStatus(!currentStatus)
  }

  // useEffect(() => {
  //   const currentStatus = SizeAdvisorUtil.getSizeAdvisorPlpToggleStatus()

  //   if (frameGeniusData.analysisResults.size && currentStatus !== state.isSizeAdvisorActive) {
  //     SizeAdvisorUtil.setSizeAdvisorPlpToggleStatus(!currentStatus)
  //     toggleHingesFacet()
  //   }
  // }, [frameGeniusData.analysisResults.size, state.isSizeAdvisorActive])

  const filterByHingeDistance = () => {
    // keep here for future use
  }

  const isSizeAdvisorToggleVisible = /*hingesRangeFacet && */ !!(
    isSizeAdvisorEnabled &&
    suggestedSize?.minHinge &&
    suggestedSize?.maxHinge
  )

  if (isHingesFilterApplicable() && !plpState.openDrawerFilters) {
    filterByHingeDistance()
  }

  const handleRangeSliderOpen = () => {
    dispatch({
      type: 'SET_FILTER_PRICE_RANGE',
      payload: {
        isOpen: !state.priceRange.isOpen,
      },
    })
  }
  const discountItems = results?.hierarchicalFacets?.[0]?.data

  return (
    <>
      <StyledFiltersDrawer
        anchor="right"
        ModalProps={{ keepMounted: true }}
        open={plpState.openDrawerFilters}
        data-testid="filters-menu-drawer-element"
        onClose={() => {
          plpDispatch({
            type: 'TOGGLE_DRAWER_FILTERS',
            payload: false,
          })
        }}
        onTransitionEnd={() => {
          plpDispatch({
            type: 'TOGGLE_DRAWER_FILTERS',
            payload: plpState.openDrawerFilters,
          })
        }}
      >
        <StyledFiltersDrawerContent>
          <StyledFiltersHeader>
            <StyledFiltersHeaderTitle>
              {t('ProductFilter.Labels.Filters')}
              &nbsp;
            </StyledFiltersHeaderTitle>
            <StyledCloseIconButtonContainer></StyledCloseIconButtonContainer>
          </StyledFiltersHeader>
          {isSizeAdvisorToggleVisible && (
            <SizeAdvisorSuggestion toggleHingesFacet={toggleHingesFacet} enabled={state.isSizeAdvisorActive} />
          )}
          {state.suggestedPlpFacets && state.suggestedPlpFacets.length > 0 && (
            <SuggestedFacetFilterWrapper suggestedFacets={state.suggestedPlpFacets} onFacetChange={onFacetChange} />
          )}
          {state.currentPlpFacets
            ? filteredOrderedFacets.map(({ key, value }) => {
                return value.length > 0 ? (
                  <FilterListAlgolia
                    key={key}
                    facet={key}
                    facetValues={value}
                    discountItems={discountItems}
                    onFacetChange={onFacetChange}
                    sizeAdvisorSwitcherProps={{
                      toggleHingesFacet,
                      enabled: state.isSizeAdvisorActive,
                    }}
                    hasSuggested={state.suggestedPlpFacets && state.suggestedPlpFacets.length > 0}
                  />
                ) : null
              })
            : null}
          {state.priceRange.shouldRender && (
            <StyledAccordion defaultExpanded={false}>
              <StyledAccordionSummary
                expandIcon={<SVGIcon library="arrow" name="arrow-down" />}
                onClick={handleRangeSliderOpen}
              >
                {t('ProductFilter.Labels.price')}
              </StyledAccordionSummary>
              {state.priceRange.isOpen && (
                <RangeSlider
                  attribute={`sortPrice_${customerSegment}`}
                  selectedRange={state.priceRange.values}
                  onRangeSliderChange={onRangeSliderChange}
                />
              )}
            </StyledAccordion>
          )}
        </StyledFiltersDrawerContent>
        <StyledFiltersFooter>
          <ClearAllStyledButtonAsLink onClick={() => onClearAll()}>
            {t('ProductGrid.Actions.clearAll')}
          </ClearAllStyledButtonAsLink>
          <SeeResultsStyledButton
            labeltext={
              t('ProductFilter.Labels.seeNResults', {
                total: nbHits,
              }) + ' '
            }
            onClick={onResultPress}
            loading={__isArtificial}
            variant={'secondary'}
            disabled={nbHits === 0}
          />
        </StyledFiltersFooter>
      </StyledFiltersDrawer>
    </>
  )
}

export default PlpFilterAlgolia
